import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SectionContainer from '../../components/SectionContainer';

const SectionOne = () => (
  <SectionContainer num={1}>
    <div className="row">
      <div className="hero">
        <h1>
          <FormattedMessage
            id="business.canadiansMissedWork"
            defaultMessage="500,000 Canadians missed work this week due to mental health issues."
          />
        </h1>
        <FormattedHTMLMessage
          tagName="p"
          id="business.canadasModernSolution"
          defaultMessage="Why?<br/>
            The current benefits landscape is not set up to actually offer support.
            Inkblot is fixing mental health in Canada, starting in the workplace."
        />
      </div>
    </div>
  </SectionContainer>
);

SectionOne.propTypes = {};

export default SectionOne;
