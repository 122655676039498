import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../components/SectionContainer';
import { SERVER_URL } from '../../utils/environment';

class SectionSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailButton: 'business.getInTouch',
      sendEmailDisabled: false,
      selectedPractice: false,
    };
  }

  onSelectPractice = i => {
    const { title } = i.target;
    this.setState(prevState => ({ [title]: !prevState[title] }));
  };

  handleSubmit = event => {
    event.preventDefault();
    this.sendEmail({ fields: this.state });
  };

  sendEmail = () => {
    const values = {
      fields: _.omit(this.state, [
        'sendEmailButton',
        'sendEmailButtonDisabled',
        'sendEmailDisabled',
        'selectedPractice',
      ]),
    };
    axios.post(`${SERVER_URL}/api/business_emails/`, values).then(() => {
      this.setState({ sendEmailButton: 'EMAIL SENT!', sendEmailDisabled: true });
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderWellnessPractice = obj =>
    _.map(obj, ({ titleId, image, defaultMessage }, i) => (
      <div key={i} className="wellness-wrapper">
        <img
          role="presentation"
          src={image}
          alt={defaultMessage}
          title={<FormattedMessage id={titleId} defaultMessage={defaultMessage} />}
          className={`wellness-circle ${this.state[titleId] ? 'selected' : ''}`}
          onClick={this.onSelectPractice}
        />
        {<FormattedMessage id={titleId} defaultMessage={defaultMessage} />}
      </div>
    ));

  render() {
    const language = localStorage.getItem('selectedLanguage');
    return (
      <SectionContainer num={6} id="email">
        <h1 className="title">
          <FormattedMessage
            id="business.letsMakeCanadaBetter"
            defaultMessage=""
            values={{ teamEN: <span>you and your team.</span> }}
          />
        </h1>
        <form className="form-container" onSubmit={this.handleSubmit}>
          <div className="input-container">
            <p>
              <FormattedMessage
                id="business.howShouldWeReferYou"
                defaultMessage="How should we refer to you"
              />
            </p>
            <input
              onChange={value => this.handleChange(value)}
              type="text"
              name="name"
              placeholder={language === 'fr' ? 'Votre nom' : 'Your name'}
              required
            />
          </div>
          <div className="input-container">
            <p>
              <FormattedMessage
                id="business.howCanWeContactYou"
                defaultMessage="How can we contact you"
              />
            </p>
            <input
              onChange={value => this.handleChange(value)}
              type="email"
              name="email"
              placeholder={language === 'fr' ? 'Votre adresse de courriel' : 'Your email'}
              required
            />
          </div>
          <div className="input-container">
            <p>
              <FormattedMessage
                id="business.whatBusinessAreYouRepresenting"
                defaultMessage="What business are you representing?"
              />
            </p>
            <input
              onChange={value => this.handleChange(value)}
              type="text"
              name="business"
              placeholder={language === 'fr' ? ' Votre entreprise' : 'Your business'}
              required
            />
          </div>
          <div className="input-container">
            <p>
              <FormattedMessage
                id="business.whatIsYourRoleWithinThatBusiness"
                defaultMessage="What is your role within that business?"
              />
            </p>
            <input
              onChange={value => this.handleChange(value)}
              type="text"
              name="role"
              placeholder={language === 'fr' ? 'Votre rôle' : 'Your role'}
              required
            />
          </div>
          <button
            className="getInTouch"
            style={{
              marginTop: '50px',
            }}
            type="submit"
            disabled={this.state.sendEmailDisabled}
          >
            <FormattedMessage id="business.getInTouch" defaultMessage="Let's Get In Touch" />
          </button>
        </form>
      </SectionContainer>
    );
  }
}

SectionSix.propTypes = {};

export default SectionSix;
