/* eslint-disable max-len */
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../components/SectionContainer';
import CloseIcon from '../../images/business/events/mixer/bios/close.svg';

const faqs = [
  {
    question: 'Why is Inkblot valuable to my business?',
    answer: (
      <div>
        <p>Mental health problems affect 1 in 5 employees yet, more often than not, symptoms go unrecognized. Sufferers are reluctant to seek help, primarily due to stigma and lack of access to care, and over two-thirds go untreated. The cost of disability leave from mental illness is double that of leave due to physical illness, with the average employee losing the equivalent of 25 days per year. In addition, on average, each disengaged employee costs its business $2500 per year. The entire company and its productivity are negatively impacted when employee mental health is not supported.</p>
        <p>If implemented effectively, mental health promotion, prevention & early intervention will address these issues. As well as promoting mental wellness and educating the workplace about the importance of mental health, Inkblot allows employees to access help quickly, enabling them to address issues that surface before they start to significantly impact their well-being.</p>
        <p>Inkblot’s mental health services address all areas of the mental health spectrum and your employees will have access to expert-led interactive seminars, on-site workshops, and video counselling in the form of small group and one-on-one sessions.</p>
      </div>
    ),
  },
  {
    question: 'How does Inkblot differ from Employee Assistance Programs (EAPs)?',
    answer: (
      <div>
        <p>Workplace benefits and traditional Employee Assistance Programs are not always helpful in managing employees’ mental health difficulties. Most EAP companies depend on low utilization rates and generally outsource the majority of their clinical services to the lowest priced agencies who, in turn, tend to use inexperienced counsellors.</p>
        <p>In addition, the current pricing structure de-incentivizes early intervention, high-quality treatment, and relapse prevention. Instead of competing on metrics of employee wellness and improved outcomes, the focus has been on the diversity of services offered and utilization rates, which are bolstered by the number of website visits and phone calls.</p>
        <p>Given how much employers spend on EAPs and employee mental health, employees deserve far better care, and employers deserve a far better return on investment.</p>
        <p>Inkblot for Business is a novel solution that aligns the interests of employers, employees, and healthcare providers, and removes multiple levels of management and overhead found in traditional EAP offerings. We have automated the use of evidence-based quality metrics, which has created a revolutionary, cost-effective pricing model.</p>
      </div>
    ),
  },
  {
    question: 'Who is affected by mental health issues and how are they treated?',
    answer: (
      <div>
        <p>Mental health issues affect all of us and influence the way we think about ourselves, relate to others, and interact with the world around us. They affect our thoughts, feelings, abilities and behaviours, and are more likely to come up during times of stress or uncertainty, which are an inevitable part of life. When we experience mental health issues, our overall functioning is low, and all aspects of our lives, including our work, are affected in negative ways.</p>
        <p>Fortunately, psychotherapy (or counselling) is the best way to address these issues and with Inkblot video counselling, sessions take place at home or a quiet space at work. Inkblot matches you with a certified counsellor based on your needs and preferences. No referral is necessary, you can just sign up and book an appointment online.</p>
        <p>Many people are not able to talk about their mental health problems because they worry about being judged. By offering Inkblot to your employees, you’re helping remove the stigma around these issues and encouraging them to be proactive about their mental and emotional well-being.</p>
        <p>Psychotherapy is not just for treating mental health issues, it’s also a form of self-care - a way to improve and maintain your mental wellness.</p>
      </div>
    ),
  },
  {
    question: 'How do I identify mental health issues in the workplace?',
    answer: (
      <div>
        <p>Mental health problems affect 1 in 5 employees yet, more often than not, symptoms go unrecognized. Some signs of mental health issues in the workplace to look out for include, but are not limited to:</p>
        <ul>
          <li>• Increased frequency of sick/absent days</li>
          <li>• Loss of motivation, lack of focus, incomplete duties or tasks</li>
          <li>• Irritability, frustration and over-reaction</li>
          <li>• Disorganized, failure to meet deadlines</li>
          <li>• Decreased social interaction and deteriorating relationships</li>
          <li>• Fatigue, tiredness, excessive yawning</li>
          <li>• Sweaty and panicky</li>
          <li>• Need for constant reassurance about performance</li>
          <li>• Need for discipline due to behaviour or other problems</li>
          <li>• Excessive substance/alcohol use</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What topics does Inkblot cover in their seminars and workshops?',
    answer: (
      <div>
        <p>As part of our subscription package, we provide webinars, which feature engaging break-out groups.</p>
        <p>We customize topics most pertinent to a given workplace such as work/life balance, burnout, interpersonal communication, alcohol/substance abuse, and focus on sick leave prevention.</p>
        <p>We also provide seminars and workshops for managers based on their needs and to help them identify and handle mental health issues in the workplace.</p>
      </div>
    ),
  },
  {
    question: 'How much does Inkblot for Business cost?',
    answer: (
      <div>
        <p>With minimal overhead, we offer the highest-quality service across Canada at the most affordable price and we customize our packages based on your business’ needs.</p>
        <p>We are the only provider that has bulk session purchasing that can be shared amongst employees and transferred between contracts. Employees who need more help are not restricted.</p>
        <p>We provide several types of support from providing marketing initiatives to encourage your employees to use their mental health benefits already in place at no cost, to full mental health services at a low cost.</p>
      </div>
    ),
  },
  {
    question: 'What if we already have benefits for mental health counselling through our insurance plan?',
    answer: (
      <div>
        <p>Great! The majority of our counsellors are Registered Psychotherapists and Social Workers who can be covered by private health insurance so you can use your benefits toward Inkblot sessions and, since we’re half the cost of the national average for traditional therapy, you’ll get twice the amount of sessions free!</p>
      </div>
    ),
  },
];

class SectionEight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleOpenModal = () => this.setState({
    showModal: true,
  });

  handleCloseModal = () => this.setState({
    showModal: false,
  });


  render() {
    return (
      <SectionContainer style={{ minHeight: 0 }} num={8}>
        <div className="b8-title">
          <FormattedMessage
            id="business.moreQuestions"
            defaultMessage="Questions?"
          />
        </div>
        <div className="b8-button">
          <button
            className="b8b-button"
            onClick={this.handleOpenModal}
          >
            FREQUENTLY ASKED QUESTIONS
          </button>
        </div>
        <div className="b8-modal">
          <ReactModal
            onRequestClose={this.handleCloseModal}
            isOpen={this.state.showModal}
            className="b8m-faq-modal"
            overlayClassName="b8m-faq-modal-overlay"
          >
            <div className="b8mfq-contents">
              <div className="b8mfqc-close">
                {/* eslint-disable-next-line */}
                <img src={CloseIcon} onClick={this.handleCloseModal} alt="close" />
              </div>
              <div className="b8mfqc-faq">
                <div className="b8mfqcf-title">
                  <h2>Frequently Asked Questions</h2>
                </div>
                <div className="b8mfqcf-qas">
                  {
                    faqs.map((qa, index) => (
                      <div className="b8mfqcfq-qa" key={`k-${index + 1}`}>
                        <div className="b8mfqcfqq-question">
                          <h3>{qa.question}</h3>
                        </div>
                        <div className="b8mfqcfqq-answer">
                          {qa.answer}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </ReactModal>
        </div>
      </SectionContainer>
    );
  }
}

SectionEight.propTypes = {};

export default SectionEight;
