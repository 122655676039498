import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Scroll from 'react-scroll-to-element';
import _ from 'lodash';
import SectionContainer from '../../components/SectionContainer';
import { peopleData } from './index';

class SectionTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { recievePerson } = this.props;
    return (
      <SectionContainer style={{ marginTop: '100px' }} container num={2}>
        <p>
          <FormattedMessage
            id="business.seeHowInkblotCare"
            defaultMessage="See how Inkblot’s care spectrum addresses the pain points and needs of your employees."
          />
        </p>
        <p>
          <FormattedMessage
            id="business.meetTheEmployees"
            defaultMessage="Meet the employees of EVRY Org."
          />
        </p>
        <div style={{ marginTop: '70px' }} className="row center ">
          {_.map(
            peopleData,
            ({
 id, nameId, nameDefaultMessage, titleId, titleDefaultMessage, name, image,
}, i) => (
  <div key={i} className="col-4">
    <div className="circle-container-business ">
      <div className="info-circle">
        <img src={image} alt={name} />
      </div>
      <h3 style={{ marginTop: '35px' }}>{name}</h3>
      <p>
        <FormattedMessage id={titleId} defaultMessage={titleDefaultMessage} />
      </p>
      <a
        role="presentation"
        className="baby-blue-button-small"
        onClick={() => {
                      recievePerson(id);
                    }}
      >
        <Scroll type="id" element="story">
          <span>
            <FormattedMessage id={nameId} defaultMessage={nameDefaultMessage} />
          </span>
        </Scroll>
      </a>
    </div>
  </div>
            ),
          )}
        </div>
      </SectionContainer>
    );
  }
}

SectionTwo.propTypes = {
  recievePerson: PropTypes.func.isRequired,
};

export default SectionTwo;
