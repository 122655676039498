import React from 'react';
import PropTypes from 'prop-types';

const SectionContainer = ({
  id, children, num, style, container, containerStyle, className,
}) => (
  <div id={id} style={style} className={`section-container section-${num} ${className}`}>
    <div className={container ? 'container' : ''} style={containerStyle}>
      {children}
    </div>
  </div>
);

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  num: PropTypes.number.isRequired,
  style: PropTypes.object,
  container: PropTypes.bool,
  containerStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
};

SectionContainer.defaultProps = {
  style: {},
  container: false,
  containerStyle: {},
  className: '',
  id: '',
};

export default SectionContainer;
