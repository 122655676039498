import React, { Component } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../components/SectionContainer';
import { companies } from './index';

class SectionSeven extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderForwardThinkingCompanies = obj =>
    _.map(obj, ({ id, image, name }) => (
      <img key={id} src={image} alt={name} className="company" />
    ));

  render() {
    return (
      <SectionContainer style={{ minHeight: 0 }} num={7}>
        <h1>
          <FormattedMessage
            id="business.utilizedByForward"
            defaultMessage="Utilized by forward-thinking companies:"
          />{' '}
        </h1>
        <div className="forward-thinking-companies">
          <div>{this.renderForwardThinkingCompanies(companies)}</div>
        </div>
      </SectionContainer>
    );
  }
}

SectionSeven.propTypes = {};

export default SectionSeven;
