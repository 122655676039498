import React, { Component } from 'react';
import Scroll from 'react-scroll-to-element';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SectionContainer from '../../components/SectionContainer';
import { peopleData } from './index';

class SectionFive extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentPerson, recievePerson } = this.props;

    const removeSelectedPerson = _.reject(peopleData, o => o.id === currentPerson);

    return (
      <SectionContainer style={{ padding: 0, margin: 0, minHeight: 0 }} num={5}>
        <div className="row">
          <div className="col-4">
            <div className="more-stories">
              <h1>
                <FormattedMessage
                  id="business.readMoreEmployeeStories"
                  defaultMessage="Read more employee stories"
                />
              </h1>
            </div>
          </div>
          <div className="col-8">
            {' '}
            <div className="row center ">
              {_.map(
                removeSelectedPerson,
                ({
 id, name, nameId, nameDefaultMessage, image, description,
}, i) => (
  <div key={i} className="col-6">
    <div className="circle-container-business ">
      <div className="info-circle">
        <img src={image} alt={name} />
      </div>
      <h3 style={{ marginTop: '35px' }}>{name}</h3>
      <p>{description}</p>
      <a
        role="presentation"
        className="baby-blue-button-small"
        onClick={() => {
                          recievePerson(id);
                        }}
      >
        <Scroll type="id" element="story">
          <FormattedMessage id={nameId} defaultMessage={nameDefaultMessage} />
        </Scroll>
      </a>
    </div>
  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </SectionContainer>
    );
  }
}

SectionFive.propTypes = {
  currentPerson: PropTypes.number.isRequired,
  recievePerson: PropTypes.func.isRequired,
};

export default SectionFive;
