import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionContainer from '../../components/SectionContainer';
import Check from '../../images/_check.svg';
import { services } from './index';

class SectionFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfSteps: 3,
    };
  }

  componentWillReceiveProps(props) {
    this.slider.slickNext(props.currentPerson);
  }

  changeStep = step => {
    this.slider.slickNext(step);
    this.props.recievePerson(step);
  };

  currentUser = personID => {
    let nameId = '';
    if (personID === 0) {
      nameId = 'business.jodyPotential';
    } else if (personID === 1) {
      nameId = 'business.alishaPotential';
    } else if (personID === 2) {
      nameId = 'business.oscarPotential';
    }
    return nameId;
  };

  renderServiceList = services =>
    _.map(services, service => (
      <div key={services.indexOf(service)} className="services-container">
        <img className="check" src={Check} alt="check" />
        <div>
          <h1>
            <FormattedMessage id={service.titleId} defaultMessage={service.titleDefaultMessage} />
          </h1>
          <ul>
            {_.map(service.points, point => (
              <li key={service.points.indexOf(point)}>
                <FormattedMessage id={point.pointId} defaultMessage={point.defaultMessage} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    ));

  renderDots = () => {
    const { numberOfSteps } = this.state;
    const { currentPerson } = this.props;
    const dots = [];

    for (let i = 0; i < numberOfSteps; i += 1) {
      let dot;
      if (i !== currentPerson) {
        dot = (
          // eslint-disable-next-line
          <div
            className="business-dot-post"
            key={`key-${i + 1}`}
            onClick={() => this.changeStep(i)}
          />
        );
      } else {
        dot = <div className="business-dot-cur" key={`key-${i + 1}`} />;
      }
      dots.push(dot);
    }

    return dots;
  };

  render() {
    const sliderSettings = {
      dots: false,
      swipe: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    const { currentPerson } = this.props;
    return (
      <SectionContainer id="services" num={4}>
        <h1 className="title">
          <FormattedMessage
            id={!currentPerson ? 'business.jodyPotential' : this.currentUser(currentPerson)}
          />
        </h1>
        <Fade delay={0} duration={1500}>
          <div className="services-wrapper">
            <Slider
              ref={c => {
              this.slider = c;
            }}
              {...sliderSettings}
            >
              <div>{this.renderServiceList(services.slice(0, 3))}</div>
              <div>{this.renderServiceList(services.slice(3, 6))}</div>
              <div>{this.renderServiceList(services.slice(6))}</div>
            </Slider>
          </div>
        </Fade>
        <div className="business-dots" style={{ marginTop: '100px' }}>
          {this.renderDots()}
        </div>
      </SectionContainer>
    );
  }
}

SectionFour.propTypes = {
  currentPerson: PropTypes.number.isRequired,
  recievePerson: PropTypes.func.isRequired,
};

export default SectionFour;
