import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Scroll from 'react-scroll-to-element';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import SectionContainer from '../../components/SectionContainer';

import { peopleData } from './index';

class SectionThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfSteps: 3,
    };
  }

  componentWillReceiveProps(props) {
    this.slider.slickGoTo(props.currentPerson);
  }

  changeStep = step => {
    this.slider.slickGoTo(step);
    this.props.recievePerson(step);
  };

  renderDots = () => {
    const { numberOfSteps } = this.state;
    const { currentPerson } = this.props;
    const dots = [];
    for (let i = 0; i < numberOfSteps; i += 1) {
      let dot;
      if (i !== currentPerson) {
        dot = (
          // eslint-disable-next-line
          <div
            className="business-dot-post"
            key={`key-${i + 1}`}
            onClick={() => this.changeStep(i)}
          />
        );
      } else {
        dot = <div className="business-dot-cur" key={`key-${i + 1}`} />;
      }
      dots.push(dot);
    }

    return dots;
  };

  renderStory = selectedPerson => (
    <div className="row">
      <div className="col-5">
        <div className="circle-large">
          <div className="circle-small">
            <div>
              <p>{selectedPerson.name}</p>
              <p>
                <FormattedMessage
                  id={selectedPerson.titleId}
                  defaultMessage={selectedPerson.titleDefaultMessage}
                />
              </p>
            </div>
          </div>
          <img src={selectedPerson.image} alt={selectedPerson.name} />
        </div>
      </div>
      <div className="col-7">
        <div className="description-story">
          <p
            className="indepth-description"
            dangerouslySetInnerHTML={{
              __html:
                localStorage.getItem('selectedLanguage') === 'fr'
                  ? selectedPerson.indepthDescription.htmlFR
                  : selectedPerson.indepthDescription.htmlEN,
            }}
          />
          <Scroll type="id" element="services">
            <button
              style={{
                height: '40px',
                width: 'auto',
                padding: '0 20px',
              }}
              className="green-gradient-button"
            >
              <FormattedMessage
                id={selectedPerson.solutionId}
                defaultMessage={selectedPerson.solutionDefaultMessage}
              />
            </button>
          </Scroll>
        </div>
      </div>
    </div>
  );

  render() {
    const sliderSettings = {
      dots: false,
      swipe: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    return (
      <SectionContainer id="story" num={3}>
        <div>
          <Slider
            ref={c => {
              this.slider = c;
            }}
            {...sliderSettings}
          >
            <div style={{ height: '1000px' }}>{this.renderStory(peopleData[0])}</div>
            <div style={{ height: '1000px' }}>{this.renderStory(peopleData[1])}</div>
            <div style={{ height: '1000px' }}>{this.renderStory(peopleData[2])}</div>
          </Slider>
          <div style={{ marginTop: '100px', paddingBottom: '100px' }} className="business-dots">
            {this.renderDots()}
          </div>
        </div>
      </SectionContainer>
    );
  }
}

SectionThree.propTypes = {
  currentPerson: PropTypes.number.isRequired,
  recievePerson: PropTypes.func.isRequired,
};

export default SectionThree;
